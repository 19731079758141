const tabWrapper = document.querySelector('.msdw-buy-now-picker');
const tabButtons = tabWrapper.querySelectorAll('[role="tab"]');
const tabPanels = Array.from(tabWrapper.querySelectorAll('[role="tabpanel"]'));

function tabClickHandler(e) {
	tabPanels.forEach(panel => panel.hidden = 'true' );

	tabButtons.forEach( button => button.setAttribute('aria-selected', 'false') );

	e.currentTarget.setAttribute('aria-selected', 'true');

	const { id } = e.currentTarget;

	const currentTab = tabPanels.find(
		panel => panel.getAttribute('aria-labelledby') === id
	);

	currentTab.hidden = false;
}

tabButtons.forEach(button => button.addEventListener('click', tabClickHandler))
